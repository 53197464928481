import React,{ useState, useContext } from "react";
import './booking.css';
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from "reactstrap";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { BASE_URL } from "../../utils/config";
import axios from "axios";

const Booking = ({ tour, avgRating }) => {

    const {price, reviews, title} = tour;
    const navigate = useNavigate();

    const {user} = useContext(AuthContext);

    const [booking, setBooking] = useState({
        userId: user && user._id,
        userEmail: user && user.email,
        tourName: title,
        fullName:'',
        phone:'',
        guestSize: 1,
        rooms:'',
        bookAt:'',
    });

    const handleChange = e => {
        setBooking(prev=>({...prev, [e.target.id]:e.target.value}))
    };
    
    const serviceFee = 10
    const totalAmount = Number(price) * Number(booking.guestSize) * Number(booking.rooms) + Number(serviceFee)
    
    //send data to the server
    const handleClick = async e=>{
        e.preventDefault();

        //console.log(booking);

        try {
                if(!user || user===undefined || user===null){
                    return alert('Plaese sign in')
                }

                const orderUrl = `${BASE_URL}/payment/orders`;
                const { data } = await axios.post(orderUrl, { amount: totalAmount  });
                
                initPayment(data.data);

        } catch (err) {
            alert(err.message);
        }

        
    };

    //razorpay

	const initPayment = (data) => {
		const options = {
			key: "rzp_test_4QXj5Xr0s0NQse",
			amount: data.amount,
			currency: data.currency,
			name: tour.title,
			description: "Test Transaction",
			image: tour.photo,
			order_id: data.id,
			handler: async (response) => {
				try {
					const verifyUrl = `${BASE_URL}/payment/verify`;
					const { data } = await axios.post(verifyUrl, response);
					console.log(data);                   
                    handleBooking();
				} catch (error) {
					console.log(error);
				}
			},
			theme: {
				color: "#3399cc",
			},
		};
		const rzp1 = new window.Razorpay(options);
		rzp1.open();
	};
		

    const handleBooking = async () =>{

        console.log(booking);

        try {
            if(!user || user===undefined || user===null){
                return alert('Plaese sign in')
            }

            const res =  await fetch(`${BASE_URL}/booking`,{
                method:'post',
                headers:{
                    'content-type':'application/json'
            },
                credentials:'include',
                body:JSON.stringify(booking),
            });
            
            const result = await res.json();

            if(!res.ok){
                return alert(result.message);
            }
            navigate("/thank-you");
            
        } catch (err) {
            alert(err.message);
        }

        
    };

    //razorpay

    return <div className="booking">
        <div className="booking__top d-flex align-items-center
         justify-content-between">
            <h3>₹{price} <span>/per person</span></h3>
            <span className="tour__rating d-flex align-items-center gap-1">
                <i 
                  class="ri-star-fill" 
                  ></i> 
                  {avgRating === 0 ? null : avgRating} ({reviews?.length})
            </span>
         </div>

        {/* ========== booking form ========== */}
         <div className="booking__form">
            <h5>Information</h5>
            <Form className="booking__info-form" onSubmit={handleClick}>
                <FormGroup>
                    <input type="text" placeholder="Full Name" id="fullName"
                    required onChange={handleChange} />
                </FormGroup>
                <FormGroup>
                    <input type="number" placeholder="Phone" id="phone"
                    required onChange={handleChange} />
                </FormGroup>
                <FormGroup className="d-flex align-items-center gap-3">
                    <input 
                      type="date" 
                      placeholder="" 
                      id="bookAt"
                      required 
                      onChange={handleChange} 
                    />
                    <input 
                      type="number" 
                      placeholder="Guest" 
                      id="guestSize"
                      required
                      onChange={handleChange} 
                    />
                </FormGroup>
                <FormGroup>
                <input 
                      type="number" 
                      placeholder="Rooms" 
                      id="rooms"
                      required
                      onChange={handleChange} 
                    />
                </FormGroup>
            </Form>
         </div>
        {/* ========== booking end ========== */}

        {/* ========== booking bottom ========== */}
        <div className="booking__bottom">
            <ListGroup>
                <ListGroupItem className="border-0 px-0">
                    <h5 className="d-flex align-items-center gap-1">
                       ₹{price} <i class="ri-close-line"></i> 1 person
                    </h5>
                    <span> ₹{price}</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 px-0">
                    <h5>Service charge</h5>
                    <span> ₹{serviceFee}</span>
                </ListGroupItem>
                <ListGroupItem className="border-0 px-0 total">
                    <h5>Total</h5>
                    <span> ₹{totalAmount}</span>
                </ListGroupItem>
            </ListGroup>

            <Button className="buy_btn btn primary__btn w-100 mt-4" onClick={handleClick}>Book Now</Button>
        </div>
    </div>
}

export default Booking;